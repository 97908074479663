
























import eventHub from "@/eventHub";
import { CustomerInfoModel } from "@/models/customerModels";
import { AddedSkuToCartResponse, AddSkuToCart, AddSkuToCartRequest } from "@/models/productModel";
import { defineComponent, computed, watch, onMounted, ComputedRef, ref } from "@vue/composition-api";
import axios from "axios";

export default defineComponent({
	setup(props, context) {
		const lang = computed(() => context.root.$store.state.appCulture);
		const currentCustomer: ComputedRef<CustomerInfoModel> = computed(() => context.root.$store.state.currentCustomer);
		
		const addProductsToCart = (products: any[]) => {
			debugger;
			const productIds = products.map(p => p.productId)
			isAddingToCart.value = true;
			if (productIds != null && productIds.length > 0) {
				var request = new AddSkuToCartRequest();
				productIds.forEach(x => {
					if (request.products.find(r => r.productId == x) == null) {
						var p = new AddSkuToCart();
						p.customerId = currentCustomer.value ? currentCustomer.value.id : null;
						p.productId = x;
						p.quantity = 1;
						request.products.push(p);
					}
				});
				axios
					.post<AddedSkuToCartResponse>(`cart/addproducts`, request)
					.then(res => {
						context.root.$store.commit("setCurrentCart", res.data.cart);
						res.data.products.forEach(x => context.root.$set(x, "source", "myareaPage"));
						eventHub.$emit(eventHub.events.CART_UPDATED, res.data.products);
					})
					.catch(error => {
						console.error(error);
					})
					.finally(() => (isAddingToCart.value = false));
			} else isAddingToCart.value = false;
		};

		const goToProductPage = (productId: number) => context.root.$router.push({ path: `/product/${productId}` });
		
		const goToOrderPage = orderId => context.root.$router.push({ path: "order/" + orderId }); // console.log("go to order " + orderId)

		const getAuthToken = () => localStorage.getItem("ConvPortal-Token");

		let widgetInstance;

		const isAddingToCart = ref(false);
		const widgetOptions = {
			apiBasePath: `${process.env.VUE_APP_BASE_URL}/myarea`,
			tokenCallback: getAuthToken,
			orderDetailCallback: goToOrderPage,
			productDetailCallback: goToProductPage,
			addProductsToCartCallback: addProductsToCart,
			enableAddressesEditing: false,
			currentCustomerId: currentCustomer.value ? currentCustomer.value.id : null
		};

		const loadWidget = async () => {
			try {
				widgetInstance = await (window as any).initEcommerceMyArea("#kd-widget", lang.value, widgetOptions);
			} catch (error) {
				console.log("Errore inizializzazione widget myArea: " + error);
			}
		};

		onMounted(async () => {
			await loadWidget();
		});

		
		const showCustomerModal = ref(false);

		const onSelectCustomer = (customer: CustomerInfoModel) => {
			context.root.$store.commit("setCurrentCustomer", customer);
			showCustomerModal.value = false;
		};

		watch(currentCustomer, (newCustomer, oldCustomer) => {
			if (widgetInstance) {
				widgetInstance.setCurrentCustomerId(newCustomer ? newCustomer.id : null);
			}
		});

		return { currentCustomer, showCustomerModal, onSelectCustomer, isAddingToCart };
	}
});
